export const firebaseConfig = {
    apiKey: "AIzaSyAiQAd5tHafuzK-Qkebpq01Aib__h03tYc",
    authDomain: "skoda-eoffice-bec93.firebaseapp.com",
    projectId: "skoda-eoffice-bec93",
    storageBucket: "skoda-eoffice-bec93.appspot.com",
    messagingSenderId: "59335113564",
    appId: "1:59335113564:web:78687415e195ce4faddf99",
    measurementId: "G-KKHK3M2HVE"
}

export const publicVapidKey = 'BKC91LYXBmx1X8-vQLnQSPaO3KAgRYdzRLIfG7xPiBmg8WZ_Aj8mAuzUoDLVfOnJtLYIE9EAtvvce_YIEaAxkF4'
