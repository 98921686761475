export interface deliveryList {
    hoSoId: number;
    maHoSo: string;
    ngayTao: string;
    tenNguoiTao: string;
    tenPhongBanNguoiTao: string;
    tenNguoiNhan: string;
    diaChiNguoiNhan: string;
    loaiDonDisplay: string;
    hinhThucChuyenDisplay: string;
    trangThaiDisplay: string;
    donViVanChuyenDisplay: string;
    donViVanChuyenURL: string;
    maVanDon: string;
    ngayCapNhanVanDon: string;
    isAllowTaoLai: true,
    isAllowEdit: true,
    isAllowDelete: true,
    isAllowXacNhan: true,
    isAllowNhapVanDon: true
}

export interface deliveryDetail {
    hoSoId: number;
    tenNguoiNhan: string;
    diaChiNguoiNhan: string;
    ngayGiao: string;
    loaiDonKey: string;
    noiDung: string;
    soLuong: number;
    nguoiNhanKey: string;
    tenCongTyNhan: string;
    sdtNguoiNhan: string;
    donViVanChuyenKey: string;
    loaiBuuPhamKey: string;
    trongLuong: number;
    loaiThanhToanKey: string;
    dongGoiKey: string;
    hinhThucChuyenKey: string;
    [item: string]: any;
}

export enum EDeliveryType {
    NORMAL_EXPRESS = 'normal_express',
    SUPPER_EXPRESS = 'supper_express',
    // PARCEL = 'parcel'
    HAND_EXPRESS = 'thu_chuyen_tan_tay',
    HIGH_VALUE_EXPRESS = 'chuyen_hanghoa_giatricao',
    NORMAL_VALUE_EXPRESS = 'chuyen_hanghoa_thuong'
}

export enum EDeliveryName {
    NORMAL_EXPRESS = 'Thư thường (Gửi thư trong giờ hành chính, từ 2 - 3 ngày)',
    SUPPER_EXPRESS = 'Thư hỏa tốc (Gửi thư từ 24 giờ - 36 giờ, bao gồm ngoài giờ hành chính)',
    // PARCEL = 'Hàng hóa'
    HAND_EXPRESS = 'Thư chuyển tận tay',
    HIGH_VALUE_EXPRESS = 'Chuyển phát hàng hóa có giá trị cao (Chuyển phát đảm bảo)',
    NORMAL_VALUE_EXPRESS = 'Chuyển phát hàng hóa thường'
} 