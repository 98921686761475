import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { AuthenticationResult } from '@azure/msal-browser';
import { AuthService } from 'src/app/modules/auth';
import { ApiService } from '../http/api.service';
import { authLocalStorageToken, odooInfo } from '../shared/common/helper';
import swal from 'sweetalert';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class SSOMsalService {

  constructor(
    private authMsalService: MsalService,
    private authService: AuthService,
    private api: ApiService,
    private translate: TranslateService
  ) {
  }

  /**
   * storage sso msal token
   * @param payload payload data from msal when login success
   */
  storageSsoMsalToken(payload, router){
    this.authService.loginOdooSystem({accessToken: payload.accessToken})
    .subscribe(async odooRes => {
      const {result: {code, message, data:{access_token, employee_id}}} = odooRes;
      if(!employee_id?.id || code !== 200) {
        const defaultMsg = "Có lỗi xảy ra";
        const userNotFoundMsg = "Không tìm thấy user trong hệ thống. Vui lòng đăng nhập user khác!";
        this.api.loadingCustomOff();
        swal({
          icon: "error",
          title: !employee_id?.id ? userNotFoundMsg : message ?? defaultMsg,
          buttons: {
            ok: this.translate.instant('COMMON.btn.close'),
          },
        } as any).then(() => {
          this.authService.logout();
        })
      }else{
        localStorage.setItem(authLocalStorageToken, JSON.stringify({
            accessToken: payload.accessToken,
            // expires: payload.expiresOn.getTime(),
            idToken: payload.idToken,
            employeeID: employee_id?.id,
            tokenOdoo: access_token
        }));
        localStorage.setItem(odooInfo, JSON.stringify(odooRes?.result?.data))
        await this.authService.getUserByToken().toPromise();
        this.authMsalService.instance.setActiveAccount((<AuthenticationResult>payload).account);
        router.navigate(['/']);
      }
    }, error => {
      this.api.errorMessage(error)
    })
  }

  /**
   * check active account
   */
  checkAndSetActiveAccount(){
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    let activeAccount = this.authMsalService.instance.getActiveAccount();

    if (!activeAccount && this.authMsalService.instance.getAllAccounts().length > 0) {
      let accounts = this.authMsalService.instance.getAllAccounts();
      this.authMsalService.instance.setActiveAccount(accounts[0]);
    }
  }
}