import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable, Subject, forkJoin, of } from 'rxjs'
import { ApiService } from './../../shared/http/api.service'
import {
  assistantMails,
  firstAndLastDayOfMonth, sumissionMenuTitle, tcgDocumentMenuTitle, workLocalInfo
} from './../../shared/shared/common/helper'
import { DynamicAsideMenuConfig } from '../../configs/dynamic-aside-menu.config'
import { orderBy } from 'lodash-es'
import { DatePipe } from '@angular/common'
import { AuthService } from 'src/app/modules/auth'
import { MsalService } from '@azure/msal-angular'

const emptyMenuConfig = {
  items: []
}

export interface MyJob {
  thanhToanTamUng: number
  toTrinh: number
  yeuCauMuaHang: number
  nhanSu: number
  pheDuyetKhac: number
  congViec: number
  xeDuaDon: number
  chuyenPhatNhanh: number
  hoSoVanPhongPham: number
  kpiQuy: number
  kpiMucTieuNamBoPhan: number
  keHoachCongTac: number
  dieuDongCongTac: number
  [item: string]: number
}

interface Badges {
  pathParent: string
  pathChildren1: string
  pathChildren2: string
  count: number
  total1: number // Hiển thị badges tổng ngoài cùng
  total2: number // Hiển thị badges tổng cấp thứ nhất
}

@Injectable({
  providedIn: 'root'
})
export class DynamicAsideMenuService {
  private menuConfigSubject = new BehaviorSubject<any>(emptyMenuConfig)
  public menuConfig$: Observable<any>
  private menuReportConfigSubject = new BehaviorSubject<any>(emptyMenuConfig)
  public menuReportConfig$: Observable<any>
  private menuReportPermissionSubject = new Subject<boolean>()
  public menuReportPermission$: Observable<any>
  private menuDocumentConfigSubject = new BehaviorSubject<any>(emptyMenuConfig)
  public menuDocumentConfig$: Observable<any>
  private breadcrumbDocumentConfigSubject = new BehaviorSubject<any>(emptyMenuConfig)
  public breadcrumbDocumentConfig$: Observable<any>
  private managementDocumentTreeConfigSubject = new BehaviorSubject<any>(emptyMenuConfig)
  public managementDocumentTreeConfig$: Observable<any>
  public menuPermission$ = new BehaviorSubject(null)
  public menuConfig = { ...DynamicAsideMenuConfig } as any
  // Tổng mua hàng
  public totalMuaHang$ = new BehaviorSubject(0)
  // Tổng tờ trình
  public choToiPheDuyet$ = new BehaviorSubject<MyJob>(null)
  public totalToTrinh$ = new BehaviorSubject(0)
  // Tổng nhân viên
  public totalNhanVien$ = new BehaviorSubject(0)
  // Tổng hành chính
  public totalHanhChinh$ = new BehaviorSubject(0)
  // Tổng KPI
  // public totalKPI$ = new BehaviorSubject(0)
  // Tổng Nhân sự
  // public totalNhanSu$ = new BehaviorSubject(0)

  public isDisableMenu = false

  private disableMenuSubject = new Subject<boolean>()
  public disableMenu$: Observable<boolean>

  public fromDate = this.datepipe.transform(
    firstAndLastDayOfMonth(
      new Date(new Date().setDate(new Date().getDate() - 20))
        .getFullYear()
        .toString(),
      (
        new Date(new Date().setDate(new Date().getDate() - 20)).getMonth() + 1
      ).toString()
    ).firstDate,
    'yyyy-MM-dd'
  )

  public toDate = this.datepipe.transform(
    firstAndLastDayOfMonth(
      new Date().getFullYear().toString(),
      (new Date().getMonth() + 2).toString()
    ).lastDate,
    'yyyy-MM-dd'
  )

  constructor(
    private api: ApiService,
    private datepipe: DatePipe,
    private authService: AuthService,
    private authMsalService: MsalService
  ) {
    this.menuConfig$ = this.menuConfigSubject.asObservable()
    this.menuReportConfig$ = this.menuReportConfigSubject.asObservable()
    this.menuDocumentConfig$ = this.menuDocumentConfigSubject.asObservable()
    this.menuReportPermission$ = this.menuReportPermissionSubject.asObservable()
    this.breadcrumbDocumentConfig$ = this.breadcrumbDocumentConfigSubject.asObservable()
    this.managementDocumentTreeConfig$ = this.managementDocumentTreeConfigSubject.asObservable()
    this.disableMenu$ = this.disableMenuSubject.asObservable()
  }

  private checkUserIsAssitant(res) {
    let toTrinhMenuIndex = (<any[]>res?.items ?? [])
      .findIndex(i => i.title === sumissionMenuTitle)
    
    if(toTrinhMenuIndex >= 0) {
      const userEmail = JSON.parse(localStorage.getItem(workLocalInfo))?.workEmail
      const expenseReportMenu = [{
        root: true,
        bullet: 'dot',
        icon: 'flaticon2-user-outline-symbol',
        svg: './assets/media/svg/icons/Files/Folder-check.svg',
        title: 'MENU.REPORT.EXPENSE_REPORT_CMS',
        page: '/assistant/submissions-cms',
        screenKey: 'allow'
      },{
        root: true,
        bullet: 'dot',
        icon: 'flaticon2-user-outline-symbol',
        svg: './assets/media/svg/icons/Files/Export.svg',
        title: 'MENU.REPORT.ASSISTANT_EXPORT_REPORT',
        page: '/assistant/assistant-export-report',
        screenKey: 'allow'
      }]

      if(assistantMails.includes(userEmail)){
        // (<any[]>res?.items)[toTrinhMenuIndex] = {
        //   ...(<any[]>res?.items)[toTrinhMenuIndex],
        //   submenu: [
        //     ...(<any[]>res?.items)[toTrinhMenuIndex].submenu,
        //     expenseReportMenu
        //   ]
        // }

        return expenseReportMenu
      }
    }
  
    return []
  }

  private customizeDocumentMenu(res) {
    const documentMenuIndex = (<any[]>res?.items ?? [])
      .findIndex(i => i.title === tcgDocumentMenuTitle)

    if (documentMenuIndex >= 0) {
      (<any[]>res?.items)[documentMenuIndex] = {
        ...(<any[]>res?.items)[documentMenuIndex],
        // page: `https://edoc.skoda-vietnam.vn/documentShare?token=${this.authFromLocal?.accessToken}`,
        isHref: true,
        // target: '_blank',
        hasValidateFn: true,
        validateFn: () => this.redirectToEDOCSystem()
      }
    }
  }

  // MOVE FROM management-document-home.component.ts, management-document.service.ts
  // chuyển hướng sang hệ thống quản lý tài liệu EDOC
  redirectToEDOCSystem(){
    this.api.loadingCustomOn()
    this.authMsalService.acquireTokenSilent({
      scopes: ["openid", "user.read"],
      account: this.authMsalService.instance.getAllAccounts()[0],
      forceRefresh: false,
    }).subscribe(authResult => {
      this.api.loadingCustomOff()
      this.api.refreshAuthLocalStorage(authResult.accessToken)
      const redirectUrl =`https://edoc.skoda-vietnam.vn/documentShare?token=${authResult.accessToken}` 
      window.open(redirectUrl, "_blank")
    }, err => {
      console.log(err)
      this.api.loadingCustomOff()
    })
  }

  public async loadPermissions() {
    this.api.loadingCustomOn()
    await this.api
      .get('/api/menu')
      .toPromise()
      .then((data) => {
        const menu_permisson = [...data?.menuItems]
          ?.map((m) => [m?.children])
          ?.flat(2)
          ?.map((m) => ({
            controller: m?.controller,
            screenKey: m?.tenChucNang,
            action: m?.action,
            thuTuSapXep: m?.thuTuSapXep
          }))

        this.menuPermission$.next(menu_permisson)
        // Sort asc
        of(this.sorted(menu_permisson))
          .toPromise()
          .then((res) => {
            this.customizeDocumentMenu(res)
            const expenseReportMenu = this.checkUserIsAssitant(res)
            const items = [
              ...res.items,
              ...expenseReportMenu
              // reportMenu,
            ]

            this.menuConfigSubject.next({ items })
            this.api.loadingCustomOff()

            // Load badges
            this.loadAllBadge()
          })
      })
      .catch((err) => {
        this.api.loadingCustomOff()
        this.api.errorMessage(err)
      })
  }

  /**
   * Sắp xếp theo thứ tự của data BE trả về
   * @param menu_permisson
   * @param order
   * @returns
   */
  private sorted(menu_permisson, order = 'asc') {
    return {
      items: this.menuConfig?.items?.map((m0) => {
        if (m0.hasOwnProperty('submenu')) {
          return {
            ...m0,
            submenu: orderBy(
              m0?.submenu?.map((m1) => ({
                ...m1,
                thuTuSapXep: menu_permisson?.find(
                  (f1) => f1?.controller === m1?.screenKey
                )
              })),
              'thuTuSapXep',
              order
            )
          }
        } else {
          return m0
        }
      })
    }
  }

  get authFromLocal() {
    return this.authService.getAuthFromLocalStorage()
  }

  // Update data config menu and show badges
  private updateBadges(data: Badges) {
    // Append
    this.menuConfigSubject.next({
      items: this.menuConfigSubject?.value?.items?.map((m) =>
        m?.page === data.pathParent
          ? {
            ...m,
            submenu: m.submenu?.map((m1) =>
              m1.page === data.pathChildren1
                ? data.pathChildren2
                  ? {
                    ...m1,
                    submenu: m1?.submenu?.map((m2) =>
                      m2.page === data.pathChildren2
                        ? { ...m2, badges: data.count }
                        : m2
                    ),
                    totalBadges2: data.total2
                  }
                  : { ...m1, badges: data.count }
                : m1
            ),
            totalBadges: data.total1
          }
          : m
      )
    })
  }

  public loadAllBadge() {
    // Reset
    this.totalNhanVien$.next(0)
    this.totalToTrinh$.next(0)
    this.totalHanhChinh$.next(0)
    this.choToiPheDuyet$.next(null)
    this.totalMuaHang$.next(0)

    const authData = this.authFromLocal
    const body = {
      access_token: authData?.accessToken,
      from_date: this.datepipe.transform(this.fromDate, 'yyyy-MM-dd'),
      to_date: this.datepipe.transform(this.toDate, 'yyyy-MM-dd'),
      type: "all",
      page: 1,
      items_per_page: 10,
    }

    // Hành chính nhân sự
    this.api.postOffLoading('/hrm_tcg/api/v1/tcm/approval/get_approvals', body)
      .subscribe((res: any) => {
        const { result } = res
        if (result?.code === 200) {
          const { data: { data_count } } = result
          const jobRes = Object.assign({} as MyJob, { ...data_count })
          this.choToiPheDuyet$.next({
            ...this.choToiPheDuyet$.value,
            ...jobRes
          });
          this.totalNhanVien$.next(
            (jobRes?.leave ?? 0) + (jobRes?.overtime ?? 0)
            + (jobRes?.timesheet ?? 0) + (jobRes?.list_going_on_business ?? 0))
          this.updateBadges({
            pathParent: '/personnel',
            pathChildren1: '/personnel/approve/holiday',
            pathChildren2: null,
            count: jobRes?.leave ?? 0,
            total1: this.totalNhanVien$.value,
            total2: 0
          })

          this.updateBadges({
            pathParent: '/personnel',
            pathChildren1: '/personnel/approve/overtime',
            pathChildren2: null,
            count: jobRes?.overtime ?? 0,
            total1: this.totalNhanVien$.value,
            total2: 0
          })

          this.updateBadges({
            pathParent: '/personnel',
            pathChildren1: '/personnel/approve/list-going-on-bussiness',
            pathChildren2: null,
            count: jobRes?.list_going_on_business ?? 0,
            total1: this.totalNhanVien$.value,
            total2: 0
          })

          this.updateBadges({
            pathParent: '/personnel',
            pathChildren1: '/personnel/approve/work-explanation',
            pathChildren2: null,
            count: jobRes?.timesheet ?? 0,
            total1: this.totalNhanVien$.value,
            total2: 0
          })

          // Hành chính
          this.totalHanhChinh$.next(
            (jobRes?.xeDuaDon ?? jobRes?.shuttle_bus ?? 0) +
            (jobRes?.hoSoVanPhongPham ?? jobRes?.register_stationery ?? 0)
          )
          this.updateBadges({
            pathParent: '/administration',
            pathChildren1: '/administration/approve/register-vehicle',
            pathChildren2: null,
            count: jobRes?.xeDuaDon ?? jobRes?.shuttle_bus ?? 0,
            total1: this.totalHanhChinh$.value,
            total2: 0
          })
          this.updateBadges({
            pathParent: '/administration',
            pathChildren1: '/administration/approve/register-stationery',
            pathChildren2: null,
            count: jobRes?.hoSoVanPhongPham ?? jobRes?.register_stationery ?? 0,
            total1: this.totalHanhChinh$.value,
            total2: 0
          })
        }
      })

    // Tờ trình
    this.api.get('/api/trangchu/cho-toi-phe-duyet').subscribe((res: MyJob) => {
      if (res) {
        // Event bus data cho màn hình Thông tin cá nhân
        this.choToiPheDuyet$.next({
          ...this.choToiPheDuyet$.value,
          toTrinh: res?.toTrinh,
          thanhToanTamUng: res?.thanhToanTamUng
        });
        // Tờ trình
        this.totalToTrinh$.next((res?.toTrinh ?? 0) + (res?.thanhToanTamUng ?? 0));
        this.updateBadges({
          pathParent: '/submissions',
          pathChildren1: '/submissions/list',
          pathChildren2: null,
          count: res?.toTrinh ?? 0,
          total1: this.totalToTrinh$.value,
          total2: 0
        });
        this.updateBadges({
          pathParent: '/submissions',
          pathChildren1: '/submissions/requests-payment',
          pathChildren2: null,
          count: res?.thanhToanTamUng ?? 0,
          total1: this.totalToTrinh$.value,
          total2: 0
        });
      }})

    // Mua hàng
    // 1. Danh sách Yêu cầu mua sắm
    // this.api.get('/api/purchase-product-list/cho-toi-phe-duyet').subscribe(res => {
    //   if (res) {
    //     const count = res?.count ?? 0;
    //     this.totalMuaHang$.next(count + this.totalMuaHang$.value);
    //     this.updateBadges({
    //       pathParent: '/purchase',
    //       pathChildren1: '/purchase/list',
    //       pathChildren2: null,
    //       count: count,
    //       total1: this.totalMuaHang$.value,
    //       total2: 0
    //     });
    //   }
    // });

    // 2. Đề xuất lựa chọn nhà cung cấp
    // this.api.get('/api/purchase-requisition-list/cho-toi-phe-duyet').subscribe(res => {
    //   if (res) {
    //     const count = res?.count ?? 0;
    //     this.totalMuaHang$.next(count + this.totalMuaHang$.value);
    //     this.updateBadges({
    //       pathParent: '/purchase',
    //       pathChildren1: '/purchase/supplier-selection',
    //       pathChildren2: null,
    //       count: count,
    //       total1: this.totalMuaHang$.value,
    //       total2: 0
    //     });
    //   }
    // });

    // 3. Đề nghị ký hợp đồng
    // this.api.get('/api/purchase-contract-list/cho-toi-phe-duyet').subscribe(res => {
    //   if (res) {
    //     const count = res?.count ?? 0;
    //     this.totalMuaHang$.next(count + this.totalMuaHang$.value);
    //     this.updateBadges({
    //       pathParent: '/purchase',
    //       pathChildren1: '/purchase/contract',
    //       pathChildren2: null,
    //       count: count,
    //       total1: this.totalMuaHang$.value,
    //       total2: 0
    //     });
    //   }
    // });
  }

  getMenuSubject() {
    return this.menuConfigSubject
  }

  getMenuReportSubject() {
    return this.menuReportConfigSubject
  }

  getMenuReportPermissionSubject() {
    return this.menuReportPermissionSubject
  }

  getMenuDocumentSubject() {
    return this.menuDocumentConfigSubject
  }

  getBreadcrumbDocumentSubject() {
    return this.breadcrumbDocumentConfigSubject
  }

  getManagementDocumentTreeSubject() {
    return this.managementDocumentTreeConfigSubject
  }

  getDisableMenuSubject() {
    return this.disableMenuSubject
  }
}
