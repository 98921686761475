import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { EventMessage, EventType, SilentRequest } from '@azure/msal-browser';
import { SSOMsalService } from './sso-msal.service';
import { AuthService } from 'src/app/modules/auth';

@Component({
  selector: 'app-sso-msal',
  template: '<router-outlet></router-outlet>',
})
export class SsoMsalComponent implements OnInit {

  constructor(private router: Router,
    private ssoMsalService: SSOMsalService,
    private msalBroadcastService: MsalBroadcastService,
    private authMsalService: MsalService,
    private authService: AuthService) { }

  ngOnInit(): void {
    if ('serviceWorker' in navigator) {
      // firebase.initializeApp(environment.firebaseConfig);
      navigator.serviceWorker.register('/firebase-messaging-sw.js')
        .then((registration) => {
          console.log('Thông tin service worker: ', registration);
        })
        .catch((error) => {
          console.error('Đăng ký Service Worker thất bại:', error);
        });
    }
    
    this.ssoMsalService.checkAndSetActiveAccount();
    if(this.authMsalService.instance.getActiveAccount()){
      if(this.authService.getAuthFromLocalStorage()){
        this.router.navigate(['/']);
      }else{
        this.authMsalService.acquireTokenSilent({
          scopes: ["openid", "user.read"],
          account: this.authMsalService.instance.getActiveAccount(),
          forceRefresh: false,
        })
        .subscribe(result => {
            this.ssoMsalService.storageSsoMsalToken(result, this.router);
          //this.router.navigate(['/']);
        })
      }
    }
    this.msalBroadcastService.msalSubject$
      .subscribe((result: EventMessage) => {
        const {payload} = result;
        if(result.eventType === EventType.LOGIN_SUCCESS){
            this.ssoMsalService.storageSsoMsalToken(payload as SilentRequest, this.router);         
          //this.router.navigate(['/']);
        } else{
          //console.log(result.eventType);
        }
      })
  }
}
