import { Injectable, OnDestroy } from '@angular/core'
import { Observable, BehaviorSubject, of, Subscription, from } from 'rxjs'
import { map, catchError, switchMap, finalize } from 'rxjs/operators'
import { UserModel } from '../_models/user.model'
import { AuthModel } from '../_models/auth.model'
import { ApiService } from 'src/app/_metronic/shared/http/api.service'
import { MsalService } from '@azure/msal-angular'
import {
  HEADER_ACCEPT,
  authLocalStorageToken,
  odooInfo,
  workLocalInfo
} from 'src/app/_metronic/shared/shared/common/helper'
import { RequestPayload } from 'src/app/_metronic/shared/http/request-payload.model'

@Injectable({
  providedIn: 'root'
})
export class AuthService implements OnDestroy {
  // private fields
  private unsubscribe: Subscription[] = [] // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

  // public fields
  currentUser$: Observable<UserModel>
  isLoading$: Observable<boolean>
  currentUserSubject: BehaviorSubject<UserModel>
  isLoadingSubject: BehaviorSubject<boolean>
  dataDetail = null
  query = null

  get currentUserValue(): UserModel {
    return this.currentUserSubject.value
  }

  set currentUserValue(user: UserModel) {
    this.currentUserSubject.next(user)
  }

  constructor(private api: ApiService, private authMsalService: MsalService) {
    this.isLoadingSubject = new BehaviorSubject<boolean>(false)
    this.currentUserSubject = new BehaviorSubject<UserModel>(undefined)
    this.currentUser$ = this.currentUserSubject.asObservable()
    this.isLoading$ = this.isLoadingSubject.asObservable()
    const subscr = this.getUserByToken().subscribe()
    this.unsubscribe.push(subscr)
  }

  // public methods
  login(username: string, password: string): Observable<UserModel> {
    this.isLoadingSubject.next(true)
    return this.api.post('/api/Login', { username, password }).pipe(
      map((auth: AuthModel) => {
        const result = this.setAuthFromLocalStorage(auth)
        return result
      }),
      switchMap(() => this.getUserByToken()),
      catchError((err) => {
        console.error('err', err)
        return of(undefined)
      }),
      finalize(() => this.isLoadingSubject.next(false))
    )
  }

  logout() {
    // // const activeAccount = this.authMsalService.instance.getActiveAccount()
    // // if (activeAccount) {
    // localStorage.clear()
    // sessionStorage.clear()
    // this.authMsalService.logoutRedirect()
    //   // .subscribe(() => { 
    //   //   window.location.replace('/auth/login')
    //   // })
    // // } else {
    // //   window.location.replace('/auth/login')
    // //   localStorage.clear()
    // // }
    const activeAccount = this.authMsalService.instance.getActiveAccount()
    this.api.logout(this.authMsalService, activeAccount)
  }

  getUserByToken(): Observable<UserModel> {
    const auth = this.getAuthFromLocalStorage()
    if (!auth || !auth.accessToken) {
      return of(undefined)
    }

    const body = {
      access_token: auth?.accessToken,
      employee_id: parseInt(auth?.employeeID)
    }
    this.dataDetail = {}
    this.isLoadingSubject.next(true)
    this.api.loadingCustomOn('Checking user')
    return this.api
      .post('/hrm_tcg/api/v1/tcm/employee/information', body, HEADER_ACCEPT)
      .pipe(
        //switchMap(m => from([m?.result?.data?.app_data].
        map((response: any) => {
          const { result } = response
          if (result?.code === 200) {
            const app_data = result?.data?.app_data ?? []
            Object.keys(app_data).forEach((k) => {
              this.dataDetail[k] = app_data[k]?.value ?? app_data[k]
            })
            const user = {
              ...app_data,
              hoTen: this.dataDetail?.name,
              emailCongTy: this.dataDetail?.work_email,
              tenChucVu: this.dataDetail?.job?.name,
              diaChiCuTru: this.dataDetail?.current_address,
              soDienThoai: this.dataDetail?.mobile_phone,
              tenPhongBan: this.dataDetail?.department?.name,
              soMayLe: this.dataDetail?.work_phone,
              anhDaiDien: this.dataDetail?.img_url,
              id: this.dataDetail?.id,
              maBan: this.dataDetail?.department_parent?.code,
              tenBan: this.dataDetail?.department_parent?.name,
              diaChiMacDinh: this.dataDetail?.default_address,
              maNhanVien: this.dataDetail?.code,
              ham: null,
              chucDanh: null,
              maSoThue: this.dataDetail?.tax_code,
              soBHXH: this.dataDetail?.social_insurance_code,
              viTri: this.dataDetail?.position,
              bangCap: this.dataDetail?.training,
              lichSuCongTacTaiCongTy: this.dataDetail?.transfer,
              lichSuCongTacNgoaiCongTy: this.dataDetail?.transfer_outer,
              ngaySinh: this.dataDetail?.birthday
            }
            if (user) {
              this.currentUserSubject = new BehaviorSubject<UserModel>(user)
              localStorage.setItem(workLocalInfo, JSON.stringify({ workEmail: user.emailCongTy, departmentCode: user.maBan }))
            } else {
              this.logout()
            }
            return user
          } else {
            console.error("Không lấy được thông tin nhân viên")
            this.logout()
          }
        }),
        finalize(() => {
          this.isLoadingSubject.next(false)
          this.api.loadingCustomOff()
        })
      )
  }

  // need create new user then login
  registration(user: UserModel): Observable<any> {
    this.isLoadingSubject.next(true)
    return this.api.post('', user).pipe(
      map(() => {
        this.isLoadingSubject.next(false)
      }),
      switchMap(() => this.login(user.email, user.password)),
      catchError((err) => {
        console.error('err', err)
        return of(undefined)
      }),
      finalize(() => this.isLoadingSubject.next(false))
    )
  }

  forgotPassword(email: string): Observable<boolean> {
    this.isLoadingSubject.next(true)
    return this.api
      .post('/api/forgot-password', { email })
      .pipe(finalize(() => this.isLoadingSubject.next(false)))
  }

  // private methods
  private setAuthFromLocalStorage(auth: AuthModel): boolean {
    // store auth accessToken/refreshToken/epiresIn in local storage to keep user logged in between page refreshes
    if (auth && auth.accessToken) {
      localStorage.setItem(authLocalStorageToken, JSON.stringify(auth))
      return true
    }
    return false
  }

  public getAuthFromLocalStorage(): any {
    try {
      const authData = JSON.parse(localStorage.getItem(authLocalStorageToken))
      return authData
    } catch (error) {
      console.error(error)
      return undefined
    }
  }

  public getOdooInfoFromLocalStorage(): any {
    try {
      const odooData = JSON.parse(localStorage.getItem(odooInfo))
      return odooData
    } catch (error) {
      console.error(error)
      return undefined
    }
  }

  currentUserBiReport(filter?: RequestPayload) {
    return this.api.getOffLoading('/bi_tcg/account/current-user', this.query, {
      params: filter?.toParams()
    })
  }

  documentUserPermission() {
    return this.api.getOffLoading('/backend_tcg/base/users/permissions', this.query, { headers: { 'Token': 'Azure' } })
  }

  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe())
  }

  /**
   * login odoo for eoffice system
   * @param param0 param login odoo
   */
  public loginOdooSystem({ type = 'microsoft', accessToken, device_id = 'deviceId', firebaseToken = 'fcmToken' }) {
    const body = {
      type: type,
      accessToken: accessToken,
      device_id: device_id,
      firebase_token: firebaseToken
    }
    return this.api.postOffLoading('/hrm_tcg/api/v1/tcm/sign_in', body, HEADER_ACCEPT);
  }
}
